import { API_URL } from './models/AppConstent';
import axios from 'axios';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const GetLanguagesData = async (token) => {
  try {
    const res = await apiService.get(`Admin/ManageMaster/GetLanguages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableLanguage = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/EnableLanguage`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableLanguage = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/DisableLanguage`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddLanguage = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageMaster/AddLanguage`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateLanguage = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/UpdateLanguage`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};
