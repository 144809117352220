import { API_URL } from './models/AppConstent';
import axios from 'axios';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const GetLocationData = async (token, bodyObj) => {
  try {
    const res = await apiService.post(`Admin/Locations/GetLocations`, bodyObj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableLocation = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/Locations/EnableLocation`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableLocation = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/Locations/DisableLocation`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddLocation = async (token, bodyObj) => {
  try {
    const res = await apiService.post(`Admin/Locations/AddLocation`, bodyObj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateLocation = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/Locations/UpdateLocation`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeleteLocation = async (token, bodyObj) => {
  try {
    const res = await apiService.delete(
      `Admin/Locations/DeleteLocation`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};
