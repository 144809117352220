import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useRecoilState } from 'recoil';
import { AddQualification } from '../../services/serviceQualifications';

const AddQualificationModal = ({ open, handleModal, handleFetch }) => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const data = {
    deviceType: 'Web',
    sessionId: session,
    name: '',
    icon: '',
  };

  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData(data);
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddQualification = async () => {
    try {
      const res = await AddQualification(token, formData);
      // console.log('resEdit', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(
          res.data.message,
          `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
          'success',
        ).then((result) => {
          if (result.isConfirmed) {
            handleModal();
          }
        });
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', 'Something went wrong, Try again', 'error');
    }
  };

  if (!open) {
    return null;
  }

  return (
    <div
      className="modal pt-8 show"
      id="exampleModalForms"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalForms"
      aria-hidden="true"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog plan-Modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Qualification Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">Qualification Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary light "
                onClick={handleAddQualification}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger light close-btn"
                onClick={handleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQualificationModal;
