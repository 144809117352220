import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../../core/config/atoms';
import { useRecoilState } from 'recoil';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { UpdataeGeneralOrganiaztion } from '../../../services/serviceCompanies';

const EditOrganizationModal = ({ data, open, handleModal }) => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  if (!open) {
    return null;
  }

  const handleEditOrganization = async () => {
    const body = {
      deviceType: 'Web',
      sessionId: session,
      gnrlOrgId: formData.gnrlOrgId,
      type: formData.type,
      gnrlOrgName: formData.gnrlOrgName,
      logoImage: formData.logoImage,
      cityId: formData.cityId,
      locationId: formData.locationId,
    };
    try {
      const res = await UpdataeGeneralOrganiaztion(token, body);
      console.log('res', res);
      if (res.status === 200) {
        MySwal.fire('The organization has been succesfully Added!', 'success');
      }
    } catch (error) {
      console.log('err', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };
  return (
    <div
      className="modal pt-8 show"
      id="exampleModalForms"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalForms"
      aria-hidden="true"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog plan-Modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Organization Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <p>Organization Name</p>
                  <h6>{formData.gnrlOrgName}</h6>
                </div>
                <div className="col-md-6">
                  <p>Organization Website</p>
                  <h6>
                    <a
                      href="https://www.goodmanglobalsolutions.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.goodmanglobalsolutions.com/
                    </a>
                  </h6>
                </div>
                <div className="col-md-6">
                  <p>About Company</p>
                </div>
                <div className="col-md-6">
                  <p>Company Address</p>
                  <h6>3/476 Valayapathi salai, J J Nagar,</h6>
                </div>
                <div className="col-md-12">
                  <p>Start Date</p>
                  <h6>2/17/2023</h6>
                </div>
                <div className="col-md-12">
                  <p>Description</p>
                  <h6>Overseas Jobs & Education</h6>
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary light "
                onClick={handleModal}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger light close-btn"
                onClick={handleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrganizationModal;
