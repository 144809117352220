import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import { useRecoilState } from 'recoil';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  DisableLanguage,
  EnableLanguage,
  GetLanguagesData,
} from '../../services/serviceLanguages';
import { Trash2, Edit, X, CheckCircle } from 'feather-icons-react';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tooltip';
import DataTable from 'react-data-table-component';
import EditLanguageModal from './EditLanguageModal';
import AddLanguageModal from './AddLanguageModal';

const PanelLanguages = () => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editRow, setEditRow] = useState();
  const [allLang, setAllLang] = useState();

  const handleFetch = () => setFetch(!fetch);
  const handleEditModal = () => setOpenModal(!openModal);
  const handleAddModal = () => setOpenAddModal(!openAddModal);

  const getLangData = async () => {
    setLoading(true);
    try {
      const res = await GetLanguagesData(token);
      //   console.log('data', res);
      if (res.status === 200) {
        setAllLang(res.data);
      } else {
        setAllLang([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    getLangData();
  }, [fetch]);

  const handleEnable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      id: id,
    };
    try {
      const res = await EnableLanguage(token, bodyObj);
      //   console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(
          res.data.message,
          `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
          'success',
        );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const handleDisable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      id: id,
    };
    try {
      const res = await DisableLanguage(token, bodyObj);
      //   console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(
          res.data.message,
          `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
          'success',
        );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const columns = [
    {
      name: 'Image',
      maxWidth: '100px',
      sortable: true,
      selector: (row) => {
        return (
          <div className="m-1">
            <Avatar
              round
              size="50px"
              name={row.image}
            />
          </div>
        );
      },
    },
    {
      name: 'ID',
      width: '80px',
      sortable: true,
      selector: (row) => row.langId,
    },
    {
      name: 'Short Form',
      width: '150px',
      sortable: true,
      selector: (row) => row.isoname,
    },
    {
      name: 'Name',
      sortable: true,
      grow: 2,
      // maxWidth: '800px',
      selector: (row) => {
        return <span>{row.name}</span>;
      },
    },
    {
      name: 'Direction',
      sortable: true,
      // maxWidth: '800px',
      selector: (row) => {
        return <span>{row.direction}</span>;
      },
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.status === 1 ? (
              <span className="text-success">ENABLED</span>
            ) : row.status === 2 ? (
              <span className="text-danger">DISABLED</span>
            ) : (
              <span className="text-warning">UNKNOWN</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Actions',
      right: true,
      maxWidth: '500px',
      selector: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            {row.status === 1 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Disable"
                onClick={() => handleDisable(row.langId)}
              >
                <X className="ms-2" />
              </span>
            )}
            {row.status === 2 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Enable"
                onClick={() => handleEnable(row.langId)}
              >
                <CheckCircle className="ms-2" />
              </span>
            )}
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Edit"
              onClick={() => {
                setEditRow(row);
                handleEditModal();
              }}
            >
              <Edit className="ms-2" />
            </span>
            <Tooltip id="my-tooltip" />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: '72px', // override the row height
    //   },
    // },
    headCells: {
      style: {
        fontSize: '1rem',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  return (
    <>
      <div className="hk-pg-wrapper pb-0">
        <div className="hk-pg-body py-0">
          <div className="invoiceapp-wrap">
            <div className="invoiceapp-content">
              <div className="invoiceapp-detail-wrap">
                <div className="invoice-body">
                  <div className="invoice-list-view">
                    <div className="m-2 text-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddModal}
                      >
                        Add Language
                      </button>
                    </div>
                    <DataTable
                      title="Language List"
                      customStyles={customStyles}
                      columns={columns}
                      data={allLang}
                      persistTableHead={true}
                      selectableRows
                      progressPending={loading}
                      pagination
                    />
                    <EditLanguageModal
                      data={editRow}
                      open={openModal}
                      handleModal={handleEditModal}
                      handleFetch={handleFetch}
                    />
                    <AddLanguageModal
                      open={openAddModal}
                      handleModal={handleAddModal}
                      handleFetch={handleFetch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelLanguages;
