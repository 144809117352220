import { API_URL } from './models/AppConstent';
import axios from 'axios';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const GetQualificationsData = async (token, obj) => {
  if (!obj.sessionId || !obj.DeviceType) {
    throw new Error('sessionId and DeviceType are required');
  }
  try {
    const res = await apiService.get(`Admin/ManageMaster/GetQualifications`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageNumber: obj.pageNumber,
        pageSize: obj.pageSize,
        sessionId: obj.sessionId,
        DeviceType: obj.DeviceType,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableQualification = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/EnableQualification`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableQualification = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/DisableQualification`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddQualification = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageMaster/AddQualifications`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateQualification = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/UpdateQualification`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};
