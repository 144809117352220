import axios from 'axios';
import APIResponse from './models/APIResponse';

import { PUBLIC_KEY } from './models/AppConstent';

class ServiceAPI {
    doCall(uri, body, token) { };
}

class GetAPIService extends ServiceAPI {
    async doCall(uri, body, token) {
        var requestHeaders = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        };
        try {
            var response = await axios.get(uri, body, { headers: requestHeaders });
            var main = response.data;
            return new APIResponse(
                main["data"],
                main["status"],
                main["statuscode"],
                main["msg"],
            );
        } catch (e) {
            return new APIResponse(
                null,
                false,
                400,
                "Unauthorised access.",
            );
        }
    }
}

class PostAPIService extends ServiceAPI {
    async doCall(uri, body, token) {
        var requestHeaders = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        };
        try {
            var response = await axios.post(uri, body, { headers: requestHeaders });
            var main = response.data;
            return new APIResponse(
                main["data"],
                main["status"],
                main["statuscode"],
                main["msg"],
            );
        } catch (e) {
            return new APIResponse(
                null,
                false,
                400,
                "Unauthorised access.",
            );
        }
    }
}

export default class APIServiceLookUp {
    getAPIService(serviceType) {
        if (serviceType === "GET") {
            return new GetAPIService();
        } else {
            return new PostAPIService();
        }
    }
}