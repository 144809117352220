import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, sessionAtom } from '../../core/config/atoms';

import imgA from "../../assets/image/Today's-jobs.png";
import imgB from '../../assets/image/Applied-jobs.png';
import imgC from '../../assets/image/Total-users.png';
import imgD from '../../assets/image/Total-company.png';
import imgE from '../../assets/image/circle.png';

import ServiceAuth from '../../services/serviceAuth';

export default function PanelDashboardOverview() {
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const navigate = useNavigate();
  const authService = new ServiceAuth();

  const loadData = () => {
    (async function () {
      let body = {
        deviceType: 'Web',
        sessionId: session,
      };

      var res = await authService.GetDashboardData(body, token);
      console.log(res.data);
      if (res['status']) {
      } else {
        navigate('/page/Unauthorised');
      }
    })();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div class="hk-pg-wrapper pb-0">
      <div class="hk-pg-body py-0">
        <div class="invoiceapp-wrap">
          <div class="invoiceapp-content">
            <div class="invoiceapp-detail-wrap">
              <header
                class="invoice-header"
                style={{ paddingTop: '9px' }}
              >
                <div class="d-flex align-items-center mt-3 mb-3">
                  <h2 class="main-heading">Dashboard</h2>
                </div>
                <div class="invoice-options-wrap">
                  <a
                    class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block d-none"
                    href="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Refresh"
                  >
                    <span class="btn-icon-wrap">
                      <span class="feather-icon">
                        <i data-feather="refresh-cw"></i>
                      </span>
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover hk-navbar-togglable d-lg-inline-block d-none"
                    href="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Collapse"
                  >
                    <span class="btn-icon-wrap">
                      <span class="feather-icon">
                        <i data-feather="chevron-up"></i>
                      </span>
                      <span class="feather-icon d-none">
                        <i data-feather="chevron-down"></i>
                      </span>
                    </span>
                  </a>
                </div>
              </header>

              <div class="container">
                <div class="row row-cols-xl-5 cardd">
                  <div class="col">
                    <a href="AllJobs.html">
                      <div class="card gradient-1 card-bx">
                        <div class="card-body d-flex align-items-center">
                          <div class="me-auto text-white">
                            <h2 class="text-white">676</h2>
                            <span class="fs-18">Total Jobs</span>
                          </div>
                          <img
                            src={imgA}
                            alt=""
                            width="50"
                            height="50"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a href="AllJobs.html">
                      <div class="card gradient-2 card-bx">
                        <div class="card-body d-flex align-items-center">
                          <div class="me-auto text-white">
                            <h2 class="text-white">0</h2>
                            <span class="fs-18">Today's Jobs</span>
                          </div>
                          <img
                            src={imgA}
                            alt=""
                            width="50"
                            height="50"
                          />
                        </div>
                      </div>
                    </a>
                  </div>

                  <div class="col">
                    <a href="#">
                      <div class="card gradient-6 card-bx">
                        <div class="card-body d-flex align-items-center">
                          <div class="me-auto text-white">
                            <h6 class="text-white">Total Applied </h6>
                            <span class="fs-18">Jobs</span>
                          </div>
                          <img
                            src={imgB}
                            alt=""
                            width="50"
                            height="50"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a href="WLJ-Users.html">
                      <div class="card gradient-3 card-bx">
                        <div class="card-body d-flex align-items-center">
                          <div class="me-auto text-white">
                            <h2 class="text-white">5661</h2>
                            <span class="fs-18">Total Users</span>
                          </div>
                          <img
                            src={imgC}
                            alt=""
                            width="50"
                            height="50"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a href="allCompany.html">
                      <div class="card gradient-4 card-bx">
                        <div class="card-body d-flex align-items-center">
                          <div class="me-auto text-white">
                            <h2 class="text-white">46</h2>
                            <span class="fs-18">Total Company</span>
                          </div>
                          <img
                            src={imgD}
                            alt=""
                            width="50"
                            height="50"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <h5>Recent 643 Jobs</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              id="recent-jobs"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              Quality Engineer
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">
                              Full Time
                            </span>
                            <span class="d-heading1">Intermediate</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              Senior HVAC Technician
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="ms-8 d-heading1">
                              Senior HVAC Technician
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              CNC MILLING – 5 AXIS OPERATOR / PROGRAMMER
                              (FG14012){' '}
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">TECHNICIAN</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              MAZAK CONTROL CNC MILLING OPERATOR
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">OPERATOR</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              CNC LATHE OPERATOR{' '}
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">OPERATOR</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              {' '}
                              CONVENTIONAL LATHE OPERATOR (TURNER){' '}
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">OPERATOR</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              MAINTENANCE FITTER{' '}
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">FITTER</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <img
                            src={imgE}
                            alt=""
                            width="15px"
                            style={{ marginLeft: '20px' }}
                          />
                          <h6 class="text-primary d-heading">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalForms"
                            >
                              INDUSTRIAL REFEGERATION (IR) AMMONIA TECHNICIAN{' '}
                            </a>
                          </h6>
                          <div class="d-flex flex-wrap mt-3">
                            <span class="me-auto pe-3 d-heading">Contract</span>
                            <span class="d-heading1">FITTER</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <h5>Recent Registered Company</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Shivam Electricals Pvt Ltd</td>
                          <td>2/16/2023</td>
                          <td>
                            {' '}
                            Unit No .B -11, Osiya Mataji Compound Rethi Bunder
                            Road Kalher,Bhiwandi, Thane - 421302, Maharashtra,
                            India
                          </td>
                        </tr>
                        <tr>
                          <td>Discover and Empower Gate</td>
                          <td>2/16/2023</td>
                          <td> Riyadh Saudi Arabia</td>
                        </tr>
                        <tr>
                          <td>GOODMAN GLOBAL SOLUTIONS</td>
                          <td>2/17/2023</td>
                          <td>3/476 Valayapathi salai, J J Nagar,</td>
                        </tr>
                        <tr>
                          <td>F Gheewala Human Resources Consultancy</td>
                          <td>2/17/2023</td>
                          <td>
                            3rf floor Jash Chamber P M Road opposite RBI fort ,
                            Mumbai, India, Maharashtra
                          </td>
                        </tr>
                        <tr>
                          <td>Noble Soft Solutions Private Limited</td>
                          <td>2/16/2023</td>
                          <td> Riyadh Saudi Arabia</td>
                        </tr>
                        <tr>
                          <td>Discover and Empower Gate</td>
                          <td>2/18/2023</td>
                          <td> Riyadh Saudi Arabia</td>
                        </tr>
                        <tr>
                          <td>Discover and Empower Gate</td>
                          <td>2/16/2023</td>
                          <td> Riyadh Saudi Arabia</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
