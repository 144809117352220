import React from 'react';

import Lottie from 'lottie-react';

import animSad from '../assets/anim/anim-sad.json';

export default function PageUnauthorised() {
    return (
        <>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <Lottie
                        animationData={animSad}
                        loop={true}
                        style={{
                            width: '15rem',
                            margin: '0 auto',
                            marginBottom: '1.5rem',
                        }}
                    />
                    <h2
                        style={{
                            color: '#DC2626',
                            textAlign: 'center',
                            fontSize: '9rem',
                            fontWeight: 'bold',
                        }}
                    >
                        403
                    </h2>
                    <p
                        style={{
                            color: '#111827',
                            fontSize: '1.125rem',
                            textAlign: 'center',
                            margin: '0 auto',
                        }}
                    >
                        The page you are attempting to reach is unauthorised or not available.
                    </p>
                </div>
            </div>
        </>
    )
}
