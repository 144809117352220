import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../../core/config/atoms';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AddInstitution } from '../../../services/serviceCompanies';
import { useRecoilState } from 'recoil';

const AddInstituteModal = ({ open, handleModal }) => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);
  const data = {
    name: '',
    logo: '',
    city: '',
    location: '',
    type: '',
    email: '',
    contactNo: '',
    publicUrl: '',
    website: '',
    tagline: '',
    backgroudImage: '',
    startedDate: '',
    about: '',
    description: '',
    facebook: '',
    twitter: '',
    instagram: '',
  };
  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData(data);
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddInstitution = async () => {
    const body = {
      deviceType: 'Web',
      sessionId: session,
      insttnName: formData.name,
      logoImage: formData.logo,
      cityId: formData.city,
      locationId: formData.location,
      email: formData.email,
      contactNo: formData.conctactNo,
      publicUrl: formData.publicUrl,
      website: formData.website,
      tagline: formData.tagline,
      backgroudImage: formData.backgroudImage,
      startedDate: formData.startedDate,
      about: formData.about,
      description: formData.description,
      facebook: formData.facebook,
      twitter: formData.twitter,
      instagram: formData.instagram,
    };
    try {
      const res = await AddInstitution(token, body);
      console.log('res', res);
      if (res.status === 200) {
        MySwal.fire('The Instituition has been succesfully Added!', 'success');
      }
    } catch (error) {
      console.log('err', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  if (!open) {
    return null;
  }
  return (
    <div
      className="modal pt-8 show"
      id="exampleModalForms"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalForms"
      aria-hidden="true"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog plan-Modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Institution Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row my-2">
                <div className="col-md-6">
                  <label htmlFor="name">Name:</label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="logo">Logo:</label>
                  <input
                    id="logo"
                    name="logo"
                    type="file"
                    value={formData.logo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-md-6">
                  <label htmlFor="city">City:</label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="location">Location:</label>
                  <input
                    id="location"
                    name="location"
                    type="text"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary light "
                onClick={handleModal}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger light close-btn"
                onClick={handleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInstituteModal;
