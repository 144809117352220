import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
  lookupService = new APIServiceLookUp();

  async GetDashboardData(body, token) {
    var serviceType = 'POST';
    var url = `${API_URL}Admin/Dashboard/Data`;
    var apiService = this.lookupService.getAPIService(serviceType);
    return await apiService.doCall(url, body, token);
  }
}
