import React, { useEffect } from 'react';

import FeatherIcon from 'feather-icons-react';

export default function PanelRegionCountries() {

    const loadData = () => {
        (async function () {
            // let body = {
            //     "filter": filter,
            //     "page": 1,
            //     "size": parseInt(pageSize),
            //     "searchQuery": query,
            //     "startDate": isDate ? sdate.toISOString() : "",
            //     "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            // };
            // var res = await profileService.getAllInterpreter(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setInterpreters(res.data.data);
            // } else {
            //     toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            // }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="hk-pg-wrapper pb-0">
            <div class="hk-pg-body py-0">
                <div class="invoiceapp-wrap">
                    <div class="invoiceapp-content">
                        <div class="invoiceapp-detail-wrap">
                            <header class="invoice-header">
                                <div class="d-flex align-items-center">
                                    <h2 class="main-heading">Countries</h2>
                                </div>
                                <button type="button" class="me-2 btn btn-info btn-rounded d-flex AD-btn"
                                    data-bs-toggle="modal" data-bs-target="#exampleModalForms9">
                                    <i class="fa fa-plus color-info text-white me-2" style={{ paddingTop: '3px' }}></i>
                                    Add Countries</button>
                                <div class="modal  pt-8" id="exampleModalForms9" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalForms" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Add Country Details</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <form>
                                                    <div>
                                                        <div>
                                                            <label class="form-label">Country Name</label>
                                                            <input type="text" class="form-control"
                                                                id="exampleDropdownFormEmail1" />
                                                        </div>
                                                        <div class="mt-2">
                                                            <label class="form-label">Country Code</label>
                                                            <input type="text" class="form-control"
                                                                id="exampleDropdownFormEmail1" />
                                                        </div>
                                                        <div class="mt-2">
                                                            <label class="form-label">Locale Name</label>
                                                            <input type="text" class="form-control"
                                                                id="exampleDropdownFormEmail1" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2">
                                                        <label class="form-label">Currency Code</label>
                                                        <input type="text" class="form-control"
                                                            id="exampleDropdownFormEmail1" />
                                                    </div>
                                                    <div class="mt-2">
                                                        <label class="form-label">Time Zone</label>
                                                        <input type="text" class="form-control"
                                                            id="exampleDropdownFormEmail1" />
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-primary">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div class="invoice-body">
                                <div data-simplebar class="nicescroll-bar">
                                    <div class="invoice-list-view">
                                        <table id="datable_1" class="table nowrap w-100 mb-5">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span class="form-check mb-0">
                                                            <input type="checkbox" class="form-check-input check-select-all" id="customCheck1" />
                                                            <label class="form-check-label" for="customCheck1"></label>
                                                        </span>
                                                    </th>
                                                    <th>Country Name</th>
                                                    <th>Country Code</th>
                                                    <th>Locale Name</th>
                                                    <th>Currency Code</th>
                                                    <th>Time Zone</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Afghanistan</td>
                                                    <td></td>
                                                    <td>Afghanistan</td>
                                                    <td>AFN</td>
                                                    <td>[{"zoneName:'Asia\/Kabul',gmtOffset:16200,gmtOffsetName:'UTC+04:30',abbreviation:'AFT',tzName:'Afghanistan Time'"}]</td>
                                                    <td>
                                                        <div class="d-flex">
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover" data-bs-toggle="tooltip">
                                                                <span class="btn-icon-wrap">
                                                                    <FeatherIcon icon="edit" size={18} />
                                                                </span>
                                                            </a>
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip">
                                                                <span class="btn-icon-wrap">
                                                                    <FeatherIcon icon="trash-2" size={18} />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
