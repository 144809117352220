import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
  lookupService = new APIServiceLookUp();

  async GetReportedPostData(body, token) {
    var serviceType = 'POST';
    var url = `${API_URL}Admin/ManagePost/GetUserReportedPosts`;
    var apiService = this.lookupService.getAPIService(serviceType);
    return await apiService.doCall(url, body, token);
  }

  async GetDeletedPostData(body, token) {
    var serviceType = 'POST';
    var url = `${API_URL}Admin/ManagePost/GetUserDeletedPosts`;
    var apiService = this.lookupService.getAPIService(serviceType);
    return await apiService.doCall(url, body, token);
  }
}
