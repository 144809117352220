import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, sessionAtom } from '../core/config/atoms';

import { decode as base64_decode } from 'base-64';

import ServiceAuth from '../services/serviceAuth';
import { Blocks } from 'react-loader-spinner';

export default function PageAuth() {
  const { session, token } = useParams();

  const [, setTokenData] = useRecoilState(tokenAtom);
  const [, setSessionData] = useRecoilState(sessionAtom);

  const navigate = useNavigate();
  const authService = new ServiceAuth();

  const decoded = base64_decode(session);

  const loadData = () => {
    // localStorage.setItem('token', decodeURIComponent(token));
    // localStorage.setItem('session', decodeURIComponent(session));
    // setTokenData(decodeURIComponent(token));
    // setSessionData(decodeURIComponent(session));
    localStorage.setItem('token', token);
    localStorage.setItem('session', decoded);
    setTokenData(token);
    setSessionData(decoded);
    // navigate('/dashboard');
    let body = {
      deviceType: 'Web',
      sessionId: decoded,
    };
    authService.GetDashboardData(body, token).then((res) => {
      if (res['status']) {
        navigate('/dashboard');
      } else {
        navigate('/page/unauthorised/400');
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <Blocks
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        visible={true}
      />
    </div>
  );
}
