import React from 'react'

export default function PanelRegionState() {
    return (
        <div class="hk-pg-wrapper pb-0">
            <div class="hk-pg-body py-0">
                <div class="invoiceapp-wrap">
                    <div class="invoiceapp-content">
                        <div class="invoiceapp-detail-wrap">
                            <header class="invoice-header">
                                <div class="d-flex align-items-center">
                                    <h2 class="main-heading">State</h2>
                                </div>
                                <button type="button" class="me-2 btn btn-info btn-rounded d-flex AD-btn"
                                    data-bs-toggle="modal" data-bs-target="#exampleModalForms">
                                    <i class="fa fa-plus color-info text-white me-2" style="padding-top: 3px;"></i>
                                    Add State</button>
                                <div class="modal  pt-8" id="exampleModalForms" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalForms" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Add State Details</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div class="mt-2">
                                                    <label class="form-label">State Name</label>
                                                    <input type="text" class="form-control"
                                                        id="exampleDropdownFormEmail1" />
                                                </div>
                                                <div class="mt-2">
                                                    <label class="form-label">State Code</label>
                                                    <input type="text" class="form-control"
                                                        id="exampleDropdownFormEmail1" />
                                                </div>

                                                <form>
                                                    <div class="mb-3 mt-2">
                                                        <label class="form-label">Select Country</label>
                                                        <select class="form-select">
                                                            <option selected>Select</option>
                                                            <option value="1">Afghanistan</option>
                                                            <option value="2">Alan Island</option>
                                                            <option value="2">Albania</option>
                                                            <option value="2">Algeria</option>
                                                            <option value="2">America</option>
                                                            <option value="2">Andorra</option>
                                                            <option value="2">Antarctica</option>
                                                            <option value="2">Aruba</option>
                                                            <option value="2">Autralia</option>
                                                            <option value="2">Bahrain</option>
                                                        </select>
                                                        <div class="mt-2">
                                                            <label class="form-label">Local name</label>
                                                            <input type="text" class="form-control"
                                                                id="exampleDropdownFormEmail1" />
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-primary">Save</button></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div class="invoice-body">
                                <div data-simplebar class="nicescroll-bar">
                                    <div class="invoice-list-view">
                                        <table id="datable_1" class="table nowrap w-100 mb-5">
                                            <thead>
                                                <tr>
                                                    <th><span class="form-check mb-0">
                                                        <input type="checkbox"
                                                            class="form-check-input check-select-all"
                                                            id="customCheck1" />
                                                        <label class="form-check-label" for="customCheck1"></label>
                                                    </span></th>


                                                    <th>State Name</th>
                                                    <th>State Code</th>
                                                    <th>Local Name</th>
                                                    <th>Country Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Andorra la Vella</td>
                                                    <td>3</td>
                                                    <td>	Andorra la Vella</td>
                                                    <td>United States</td>
                                                    <td>
                                                        <div class="d-flex">
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Edit"><span
                                                                    class="btn-icon-wrap"><span class="feather-icon"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalForms10"><i
                                                                            data-feather="edit"></i></span></span></a>
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Delete" href="#"
                                                                id="show-alert"><span class="btn-icon-wrap"><span
                                                                    class="feather-icon"><i data-feather="trash-2"
                                                                        id="show-alert"></i></span></span></a>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>El Tarter</td>
                                                    <td>6</td>
                                                    <td>El Tarter</td>
                                                    <td>Australia</td>
                                                    <td>
                                                        <div class="d-flex">
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Edit"><span
                                                                    class="btn-icon-wrap"><span class="feather-icon"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalForms9"><i
                                                                            data-feather="edit"></i></span></span></a>
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Delete" href="#"
                                                                id="show-alert"><span class="btn-icon-wrap"><span
                                                                    class="feather-icon"><i data-feather="trash-2"
                                                                        id="show-alert"></i></span></span></a>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>Arinsal</td>
                                                    <td>4</td>
                                                    <td>Arinsal</td>
                                                    <td>China</td>
                                                    <td>
                                                        <div class="d-flex">
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Edit"><span
                                                                    class="btn-icon-wrap"><span class="feather-icon"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalForms9"><i
                                                                            data-feather="edit"></i></span></span></a>
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Delete" href="#"
                                                                id="show-alert"><span class="btn-icon-wrap"><span
                                                                    class="feather-icon"><i data-feather="trash-2"
                                                                        id="show-alert"></i></span></span></a>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td></td>
                                                    <td>Canillo</td>
                                                    <td>8</td>
                                                    <td>Canillo</td>
                                                    <td>Canada</td>
                                                    <td>
                                                        <div class="d-flex">
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Edit"><span
                                                                    class="btn-icon-wrap"><span class="feather-icon"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalForms9"><i
                                                                            data-feather="edit"></i></span></span></a>
                                                            <a class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="" data-bs-original-title="Delete" href="#"
                                                                id="show-alert"><span class="btn-icon-wrap"><span
                                                                    class="feather-icon"><i data-feather="trash-2"
                                                                        id="show-alert"></i></span></span></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
