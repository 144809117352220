import { API_URL } from './models/AppConstent';
import axios from 'axios';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

// Organization

export const GetOrganizationData = async (token, obj) => {
  if (!obj.sessionId || !obj.DeviceType) {
    throw new Error('sessionId and DeviceType are required');
  }
  try {
    const res = await apiService.get(
      `Admin/ManageOrg/GetGeneralOrganizationList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pageNumber: obj.pageNumber,
          pageSize: obj.pageSize,
          sessionId: obj.sessionId,
          DeviceType: obj.DeviceType,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableGeneralOrganization = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/EnableGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableGeneralOrganization = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/DisableGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeleteGeneralOrganization = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/DeleteGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddGeneralOrganiaztion = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/AddGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdataeGeneralOrganiaztion = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/UpdateGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// Institution

export const GetInstitutionData = async (token, obj) => {
  if (!obj.sessionId || !obj.DeviceType) {
    throw new Error('sessionId and DeviceType are required');
  }
  try {
    const res = await apiService.get(`Admin/ManageOrg/GetInstitutionList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageNumber: obj.pageNumber,
        pageSize: obj.pageSize,
        sessionId: obj.sessionId,
        DeviceType: obj.DeviceType,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableInstitution = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/EnableInstitution`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableInstitution = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/DisableInstitution`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeleteInstitution = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/DeleteInstitution`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddInstitution = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/AddGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpadateInstiution = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageOrg/UpdateGeneralOrganization`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// export default class ServiceAuth {
//   lookupService = new APIServiceLookUp();

//   async GetOrganizationData(body, token) {
//     var serviceType = 'POST';
//     var url = `${API_URL}Admin/ManageOrg/GetGeneralOrganizationList`;
//     var apiService = this.lookupService.getAPIService(serviceType);
//     return await apiService.doCall(url, body, token);
//   }

//   async GetDeletedPostData(body, token) {
//     var serviceType = 'POST';
//     var url = `${API_URL}Admin/ManageOrg/GetInstitutionList`;
//     var apiService = this.lookupService.getAPIService(serviceType);
//     return await apiService.doCall(url, body, token);
//   }
// }
