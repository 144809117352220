import React from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { navOptionAtom } from '../core/config/atoms';

import Topbar from '../core/widgets/ui/Topbar';
import NavBar from '../core/widgets/ui/NavBar';

import PanelDashboardOverview from '../panels/dashboard/PanelDashboardOverview';
import PanelDashboardContactInfo from '../panels/dashboard/PanelDashboardContactInfo';

import PanelRegionCountries from '../panels/region/PanelRegionCountries';
import PanelRegionState from '../panels/region/PanelRegionState';
import PanelRegionCity from '../panels/region/PanelRegionCity';

import PanelDev from '../panels/dev/PanelDev';
import PanelUsers from '../panels/users/PanelUsers';
import PanelWLJUsers from '../panels/users/PanelWLJUsers';
import PanelReportedPost from '../panels/post/PanelReportedPost';
import PanelDeletedPost from '../panels/post/PanelDeletedPost';
import PanelOrganization from '../panels/companies/organizations/PanelOrganization';
import PanelInstitution from '../panels/companies/institutions/PanelInstitution';
import PanelLanguages from '../panels/languages/PanelLanguages';
import PanelFirmCategories from '../panels/firmCategory/PanelFirmCategories';
import PanelQualification from '../panels/qualification/PanelQualification';
import PanelSpecialization from '../panels/specialization/PanelSpecialization';
import PanelLocations from '../panels/locations/PanelLocations';

export default function PageLayout() {
  const [navOption] = useRecoilState(navOptionAtom);

  const { page } = useParams();

  return (
    <div
      className="hk-wrapper"
      data-layout="vertical"
      data-menu="light"
      data-footer="simple"
      {...(navOption
        ? { 'data-layout-style': 'default' }
        : { 'data-layout-style': 'collapsed', 'data-hover': 'active' })}
    >
      <Topbar />
      <NavBar />
      {(() => {
        switch (page) {
          case 'dashboard':
            return <PanelDashboardOverview />;
          case 'users':
            return <PanelUsers />;
          case 'wlj-users':
            return <PanelWLJUsers />;
          case 'reported-posts':
            return <PanelReportedPost />;
          case 'deleted-posts':
            return <PanelDeletedPost />;
          case 'organization':
            return <PanelOrganization />;
          case 'institution':
            return <PanelInstitution />;
          case 'contactInfo':
            return <PanelDashboardContactInfo />;
          case 'countries':
            return <PanelRegionCountries />;
          case 'state':
            return <PanelRegionState />;
          case 'city':
            return <PanelRegionCity />;
          case 'languages':
            return <PanelLanguages />;
          case 'firmCategory':
            return <PanelFirmCategories />;
          case 'qualification':
            return <PanelQualification />;
          case 'specialization':
            return <PanelSpecialization />;
          case 'locations':
            return <PanelLocations />;
          default:
            return <PanelDev />;
        }
      })()}
    </div>
  );
}
