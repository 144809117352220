import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PageLayout from './pages/PageLayout';
import PageAuth from './pages/PageAuth';
import PageUnauthorised from './pages/PageUnauthorised';
import Page404 from './pages/Page404';

export default function App() {

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageLayout />} />
                    <Route exact path="/:page" element={<PageLayout />} />
                    <Route exact path="/:session/:token" element={<PageAuth />} />
                    <Route exact path="/page/unauthorised/400" element={<PageUnauthorised />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}