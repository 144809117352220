import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useRecoilState } from 'recoil';
import {
  DisableSpecialization,
  EnableSpecialization,
  GetSpecializationsData,
} from '../../services/serviceSpecialization';
import DataTable from 'react-data-table-component';
import { Tooltip } from 'react-tooltip';
import { Edit, X, CheckCircle } from 'feather-icons-react';
import EditSpecializationModal from './EditSpecializationModal';
import AddSpecializationModal from './AddSpecializationModal';

const PanelSpecialization = () => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editRow, setEditRow] = useState();
  const [specialData, setSpecialData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFetch = () => setFetch(!fetch);
  const handleEditModal = () => setOpenModal(!openModal);
  const handleAddModal = () => setOpenAddModal(!openAddModal);

  const getSpecialData = async (page = 1, size = perPage) => {
    setLoading(true);
    const paramObj = {
      pageNumber: page,
      pageSize: size,
      sessionId: session,
      DeviceType: 'Web',
    };
    try {
      const res = await GetSpecializationsData(token, paramObj);
      //   console.log('main', res);
      if (res.status === 200) {
        setLoading(false);
        setSpecialData(res.data.responseData.data);
        setTotalRows(res.data.responseData.paging.totalrecords);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSpecialData(currentPage, perPage);
  }, [fetch]);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    getSpecialData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getSpecialData(page, newPerPage);
  };

  const handleEnable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      id: id,
    };
    try {
      const res = await EnableSpecialization(token, bodyObj);
      //   console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(`Enabled Specialization`, res.data.message, 'success');
        // MySwal.fire(
        //   res.data.message,
        //   `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
        //   'success',
        // );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const handleDisable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      id: id,
    };
    try {
      const res = await DisableSpecialization(token, bodyObj);
      console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(`Disbaled Specialization`, res.data.message, 'success');
        // MySwal.fire(
        //   res.data.message,
        //   `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
        //   'success',
        // );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const columns = [
    {
      name: 'ID',
      width: '80px',
      sortable: true,
      selector: (row) => row.spclznId,
    },
    {
      name: 'Name',
      sortable: true,
      //   grow: 2,
      // maxWidth: '800px',
      selector: (row) => {
        return <span>{row.name}</span>;
      },
    },
    {
      name: 'Verified',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.isVerified ? (
              <span className="text-primary">Verified</span>
            ) : (
              <span className="text-secondary">Not Verified</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Deleted',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.isDeleted ? (
              <span className="text-primary">Deleted</span>
            ) : (
              <span className="text-secondary">Not Deleted</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.status === 1 ? (
              <span className="text-success">ENABLED</span>
            ) : row.status === 2 ? (
              <span className="text-danger">DISABLED</span>
            ) : (
              <span className="text-warning">UNKNOWN</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Actions',
      right: true,
      maxWidth: '500px',
      selector: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            {row.status === 1 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Disable"
                onClick={() => handleDisable(row.spclznId)}
              >
                <X className="ms-2" />
              </span>
            )}
            {row.status === 2 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Enable"
                onClick={() => handleEnable(row.spclznId)}
              >
                <CheckCircle className="ms-2" />
              </span>
            )}
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Edit"
              onClick={() => {
                setEditRow(row);
                handleEditModal();
              }}
            >
              <Edit className="ms-2" />
            </span>
            <Tooltip id="my-tooltip" />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: '72px', // override the row height
    //   },
    // },
    headCells: {
      style: {
        fontSize: '1rem',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  return (
    <>
      {/* {console.log('main', instData)} */}
      <div className="hk-pg-wrapper pb-0">
        <div className="hk-pg-body py-0">
          <div className="invoiceapp-wrap">
            <div className="invoiceapp-content">
              <div className="invoiceapp-detail-wrap">
                <div className="invoice-body">
                  <div className="invoice-list-view">
                    <div className="m-2 text-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddModal}
                      >
                        Add Specialization
                      </button>
                    </div>
                    <DataTable
                      title="Specialization List"
                      customStyles={customStyles}
                      columns={columns}
                      data={specialData}
                      persistTableHead={true}
                      selectableRows
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                    <EditSpecializationModal
                      data={editRow}
                      open={openModal}
                      handleModal={handleEditModal}
                      handleFetch={handleFetch}
                    />
                    <AddSpecializationModal
                      open={openAddModal}
                      handleModal={handleAddModal}
                      handleFetch={handleFetch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelSpecialization;
