import React, { useEffect, useState } from 'react';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { selector, useRecoilState } from 'recoil';
import {
  DeleteLocation,
  DisableLocation,
  EnableLocation,
  GetLocationData,
} from '../../services/serviceLocation';
import DataTable from 'react-data-table-component';
import { Trash2, Edit, X, CheckCircle } from 'feather-icons-react';
import { Tooltip } from 'react-tooltip';
import AddLocationModal from './AddLocationModal';
import EditLocationModal from './EditLocationModal';

const PanelLocations = () => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editRow, setEditRow] = useState();
  const [allLocations, setAllLocations] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFetch = () => setFetch(!fetch);
  const handleEditModal = () => setOpenModal(!openModal);
  const handleAddModal = () => setOpenAddModal(!openAddModal);

  const getLocationData = async (page = 1, size = perPage) => {
    setLoading(true);
    const body = {
      deviceType: 'Web',
      sessionId: session,
      pageNumber: page,
      pageSize: size,
    };
    try {
      const res = await GetLocationData(token, body);
      //   console.log('main', res.data.responseData);
      if (res.status === 200) {
        setLoading(false);
        setAllLocations(res.data.responseData.locationList);
        setTotalRows(res.data.responseData.paging.totalrecords);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    getLocationData(currentPage, perPage);
  }, [fetch]);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    getLocationData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getLocationData(page, newPerPage);
  };

  const handleEnable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      locationId: id,
    };
    try {
      const res = await EnableLocation(token, bodyObj);
      //   console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(`Enabled Specialization`, res.data.message, 'success');
        // MySwal.fire(
        //   res.data.message,
        //   `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
        //   'success',
        // );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const handleDisable = async (id) => {
    const bodyObj = {
      deviceType: 'Web',
      sessionId: session,
      locationId: id,
    };
    try {
      const res = await DisableLocation(token, bodyObj);
      console.log('reEnables', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(`Disbaled Specialization`, res.data.message, 'success');
        // MySwal.fire(
        //   res.data.message,
        //   `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
        //   'success',
        // );
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', error.response.data.message, 'error');
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: `Are you sure you want to delete organization ID:${id}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const bodyObj = {
          deviceType: 'Web',
          sessionId: session,
          locationId: id,
        };
        try {
          const res = await DeleteLocation(token, bodyObj);
          // console.log('reEnables', res);
          if (res.status === 200) {
            handleFetch();
            MySwal.fire(
              res.data.message,
              `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
              'success',
            );
          }
        } catch (error) {
          console.log('error', error);
          MySwal.fire('Error', error.response.data.message, 'error');
        }
      }
    });
  };

  const columns = [
    {
      name: 'ID',
      width: '80px',
      sortable: true,
      selector: (row) => row.locationId,
    },
    {
      name: 'Name',
      sortable: true,
      // maxWidth: '800px',
      selector: (row) => {
        return <span>{row.locationName}</span>;
      },
    },
    {
      name: 'Coordinates',
      width: '160px',
      selector: (row) => {
        return (
          <div className="d-flex flex-column">
            <span>Latitude: {row.latitude}</span>
            <span>Longitude: {row.longitude}</span>
          </div>
        );
      },
    },
    {
      name: 'Country',
      width: '7rem',
      sortable: true,
      selector: (row) => row.countryName,
    },
    {
      name: 'State',
      width: '7rem',
      sortable: true,
      selector: (row) => row.stateName,
    },
    {
      name: 'City',
      width: '7rem',
      sortable: true,
      selector: (row) => row.cityName,
    },
    {
      name: 'Zipcode',
      width: '7rem',
      sortable: true,
      selector: (row) => row.zipCode,
    },
    {
      name: 'Deleted',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.isDeleted ? (
              <span className="text-primary">Deleted</span>
            ) : (
              <span className="text-secondary">Not Deleted</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Verified',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.isVerified ? (
              <span className="text-primary">Verified</span>
            ) : (
              <span className="text-secondary">Not Verified</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.status === 1 ? (
              <span className="text-success">ENABLED</span>
            ) : row.status === 2 ? (
              <span className="text-danger">DISABLED</span>
            ) : (
              <span className="text-warning">UNKNOWN</span>
            )}
          </div>
        );
      },
    },
    {
      name: 'Actions',
      right: true,
      maxWidth: '500px',
      selector: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            {row.status === 1 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Disable"
                onClick={() => handleDisable(row.locationId)}
              >
                <X className="ms-2" />
              </span>
            )}
            {row.status === 2 && (
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Enable"
                onClick={() => handleEnable(row.locationId)}
              >
                <CheckCircle className="ms-2" />
              </span>
            )}
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Edit"
              onClick={() => {
                setEditRow(row);
                handleEditModal();
              }}
            >
              <Edit className="ms-2" />
            </span>
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Delete"
              onClick={() => handleDelete(row.locationId)}
            >
              <Trash2 className="ms-2" />
            </span>
            <Tooltip id="my-tooltip" />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: '72px', // override the row height
    //   },
    // },
    headCells: {
      style: {
        fontSize: '1rem',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  return (
    <>
      <div className="hk-pg-wrapper pb-0">
        <div className="hk-pg-body py-0">
          <div className="invoiceapp-wrap">
            <div className="invoiceapp-content">
              <div className="invoiceapp-detail-wrap">
                <div className="invoice-body">
                  <div className="invoice-list-view">
                    <div className="m-2 text-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddModal}
                      >
                        Add Location
                      </button>
                    </div>
                    <DataTable
                      title="Locations List"
                      customStyles={customStyles}
                      columns={columns}
                      data={allLocations}
                      persistTableHead={true}
                      selectableRows
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                    <EditLocationModal
                      data={editRow}
                      open={openModal}
                      handleModal={handleEditModal}
                      handleFetch={handleFetch}
                    />
                    <AddLocationModal
                      open={openAddModal}
                      handleModal={handleAddModal}
                      handleFetch={handleFetch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelLocations;
