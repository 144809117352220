import { API_URL } from './models/AppConstent';
import axios from 'axios';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const GetFirmCategoriesData = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageMaster/GetFirmCategories`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const EnableFirmCategory = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/EnableFirmCategory`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DisableFirmCategory = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/DisableFirmCategory`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const AddFirmCategory = async (token, bodyObj) => {
  try {
    const res = await apiService.post(
      `Admin/ManageMaster/AddFirmCategory`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateFirmCategory = async (token, bodyObj) => {
  try {
    const res = await apiService.put(
      `Admin/ManageMaster/UpdateFirmCategory`,
      bodyObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    throw error;
  }
};
