import React, { useEffect, useState } from 'react';
import ServiceAuth from '../../services/servicePost';
import { useRecoilState } from 'recoil';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import { Trash2, X } from 'feather-icons-react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const PanelDeletedPost = () => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);

  const authService = new ServiceAuth();

  const [allPosts, setAllPosts] = useState([]);

  const getData = async () => {
    const body = {
      deviceType: 'Web',
      sessionId: session,
    };

    try {
      const res = await authService.GetDeletedPostData(body, token);
      if (res['status']) {
        setAllPosts(res['data']);
      } else setAllPosts([]);
    } catch (error) {
      console.log('err', error);
      setAllPosts([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleReject = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You Want to Reject Report Post!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reject it!',
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire('Rejected!', 'Your file has been rejected.', 'success');
      }
    });
  };
  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };

  const columns = [
    {
      name: '',
      maxWidth: '100px',
    },
    {
      name: 'Post Link',
      sortable: true,
      grow: 2,
      maxWidth: '800px',
      selector: (row) => {
        return (
          <span style={{ fontSize: '1rem' }}>
            <Link
              to={row.post}
              target="_blank"
            >
              {row.post}
            </Link>
          </span>
        );
      },
    },
    {
      name: 'Actions',
      right: true,
      maxWidth: '500px',
      selector: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Reject"
              onClick={() => handleReject(row.id)}
            >
              <X className="ms-2" />
            </span>
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Delete"
              onClick={() => handleDelete(row.id)}
            >
              <Trash2 className="ms-2" />
            </span>
            <Tooltip id="my-tooltip" />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: '72px', // override the row height
    //   },
    // },
    headCells: {
      style: {
        fontSize: '1rem',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const data = [
    {
      id: 1,
      post: 'https://webdemo.welinkjobs.com/Post/afe1143a-6972-45f6-9183-caa520ed0032/1208',
    },
    {
      id: 2,
      post: 'https://webdemo.welinkjobs.com/Post/afe1143a-6972-45f6-9183-caa520ed0032/1208',
    },
    {
      id: 3,
      post: 'https://webdemo.welinkjobs.com/Post/afe1143a-6972-45f6-9183-caa520ed0032/1208',
    },
  ];

  return (
    <>
      <div className="hk-pg-wrapper pb-0">
        <div className="hk-pg-body py-0">
          <div className="invoiceapp-wrap">
            <div className="invoiceapp-content">
              <div className="invoiceapp-detail-wrap">
                <div className="invoice-body">
                  <div className="invoice-list-view">
                    <DataTable
                      title="Deleted Post"
                      customStyles={customStyles}
                      columns={columns}
                      data={allPosts}
                      persistTableHead={true}
                      selectableRows
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelDeletedPost;
