import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { sessionAtom, tokenAtom } from '../../core/config/atoms';
import { UpdateLocation } from '../../services/serviceLocation';

const EditLocationModal = ({ data, open, handleModal, handleFetch }) => {
  const MySwal = withReactContent(Swal);
  const [token] = useRecoilState(tokenAtom);
  const [session] = useRecoilState(sessionAtom);
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'zipCode' || name === 'latitude' || name === 'longitude') {
      setFormData((prev) => ({ ...prev, [name]: Number(value) }));
    } else setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationUpdate = async () => {
    const body = {
      deviceType: 'Web',
      sessionId: session,
      locationId: formData.locationId,
      cityId: formData.cityId,
      locationName: formData.locationName,
      zipCode: formData.zipCode,
      latitude: formData.latitude,
      longitude: formData.longitude,
    };
    // console.log('body', body);
    try {
      const res = await UpdateLocation(token, body);
      console.log('resEdit', res);
      if (res.status === 200) {
        handleFetch();
        MySwal.fire(
          res.data.message,
          `ID: ${res.data.responseData.id}, Name: ${res.data.responseData.value}`,
          'success',
        ).then((result) => {
          if (result.isConfirmed) {
            handleModal();
          }
        });
      }
    } catch (error) {
      console.log('error', error);
      MySwal.fire('Error', 'Something went wrong, Try again', 'error');
    }
  };

  if (!open) {
    return null;
  }

  return (
    <div
      className="modal pt-8 show"
      id="exampleModalForms"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalForms"
      aria-hidden="true"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog plan-Modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Location Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="locationName">Location Name</label>
                  <input
                    type="text"
                    id="locationName"
                    name="locationName"
                    value={formData.locationName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="zipCode">Zipcode</label>
                  <input
                    type="number"
                    id="zipCode"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="number"
                    step="any"
                    id="longitude"
                    name="longitude"
                    value={formData.longitude}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="number"
                    step="any"
                    id="latitude"
                    name="latitude"
                    value={formData.latitude}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary light "
                onClick={handleLocationUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger light close-btn"
                onClick={handleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocationModal;
