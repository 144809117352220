import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { navOptionAtom } from '../../config/atoms';

import icLogo from '../../../assets/image/WLJ-logo.svg';

import icA from '../../../assets/image/company.png';
import icB from '../../../assets/image/person.png';
import icC from '../../../assets/image/information.png';

export default function NavBar() {
  const { page } = useParams();

  const [navOption, setNavOption] = useRecoilState(navOptionAtom);

  const [Tab, setTab] = useState('');

  const TabToggle = (link) => {
    if (Tab === link) {
      setTab('');
    } else {
      setTab(link);
    }
  };

  const toggleNav = () => {
    setNavOption(!navOption);
  };

  const loadData = () => {
    switch (page) {
      case 'dashboard':
      case 'contactInfo':
        TabToggle('dash');
        break;
      case 'countries':
      case 'state':
      case 'city':
        TabToggle('region');
        break;
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="hk-menu">
      <div className="menu-header">
        <span>
          <a
            className="navbar-brand"
            href="/"
          >
            <img
              className="brand-img img-fluid"
              src={icLogo}
              alt="brand"
            />
          </a>
          <button
            className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle"
            onClick={() => {
              toggleNav();
            }}
          >
            <span className="icon">
              <span className="svg-icon fs-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-bar-to-left"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    fill="none"
                  ></path>
                  <line
                    x1="10"
                    y1="12"
                    x2="20"
                    y2="12"
                  ></line>
                  <line
                    x1="10"
                    y1="12"
                    x2="14"
                    y2="16"
                  ></line>
                  <line
                    x1="10"
                    y1="12"
                    x2="14"
                    y2="8"
                  ></line>
                  <line
                    x1="4"
                    y1="4"
                    x2="4"
                    y2="20"
                  ></line>
                </svg>
              </span>
            </span>
          </button>
        </span>
      </div>

      <div
        data-simplebar
        className="nicescroll-bar"
      >
        <div className="menu-content-wrap">
          <div className="menu-group">
            <ul className="navbar-nav flex-column">
              <li className="nav-item cursor-pointer">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('dash');
                  }}
                  aria-expanded={Tab === 'dash'}
                  aria-controls="dash_log"
                  data-bs-toggle="collapse"
                  data-bs-target="#dash_log"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-template"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        />
                        <rect
                          x="4"
                          y="4"
                          width="16"
                          height="4"
                          rx="1"
                        />
                        <rect
                          x="4"
                          y="12"
                          width="6"
                          height="8"
                          rx="1"
                        />
                        <line
                          x1="14"
                          y1="12"
                          x2="20"
                          y2="12"
                        />
                        <line
                          x1="14"
                          y1="16"
                          x2="20"
                          y2="16"
                        />
                        <line
                          x1="14"
                          y1="20"
                          x2="20"
                          y2="20"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="nav-link-text">Dashboard</span>
                </a>
                <ul
                  id="dash_log"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'dash' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li
                        className={`nav-item ${
                          page === 'dashboard' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="/dashboard"
                        >
                          <span className="nav-link-text">Dashboard</span>
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          page === 'contactInfo' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="/contactInfo"
                        >
                          <span className="nav-link-text">Contact-info</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="menu-gap"></div>
          <div className="menu-group">
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('user');
                  }}
                  aria-expanded={Tab === 'user'}
                  aria-controls="user"
                  data-bs-toggle="collapse"
                  data-bs-target="#user"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-user-search"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <circle
                          cx="12"
                          cy="7"
                          r="4"
                        ></circle>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h1"></path>
                        <circle
                          cx="16.5"
                          cy="17.5"
                          r="2.5"
                        ></circle>
                        <path d="M18.5 19.5l2.5 2.5"></path>
                      </svg>
                    </span>
                  </span>
                  <span className="nav-link-text">Users</span>
                </a>
                <ul
                  id="user"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'user' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/users"
                        >
                          <span className="nav-link-text">Users</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/wlj-users"
                        >
                          <span className="nav-link-text">WLJ Users</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-2">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('post');
                  }}
                  aria-expanded={Tab === 'post'}
                  aria-controls="report"
                  data-bs-toggle="collapse"
                  data-bs-target="#report"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24px"
                        height="24px"
                      >
                        <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
                      </svg>
                    </span>
                  </span>
                  <span className="nav-link-text">Post</span>
                </a>
                <ul
                  id="report"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'post' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/reported-posts"
                        >
                          <span className="nav-link-text">Reported Post</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/deleted-posts"
                        >
                          <span className="nav-link-text">Deleted Post</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-3">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('jobs');
                  }}
                  aria-expanded={Tab === 'jobs'}
                  aria-controls="jobs"
                  data-bs-toggle="collapse"
                  data-bs-target="#jobs"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <svg
                        fill="#000000"
                        height="800px"
                        width="800px"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512.055 512.055"
                        space="preserve"
                      >
                        <g>
                          <path
                            d="M135.77,207.789c0,35.83,12.712,72.123,34.877,99.573c23.267,28.815,53.617,44.685,85.458,44.685
		c31.835,0,62.147-15.86,85.353-44.659c22.091-27.416,34.761-63.695,34.761-99.536v-32.07c23.5-74.604,14.779-137.596,7.134-154.97
		c-0.012-0.026-0.027-0.051-0.039-0.077c-0.073-0.16-0.153-0.315-0.236-0.47c-0.045-0.084-0.087-0.17-0.135-0.251
		c-0.078-0.135-0.164-0.264-0.25-0.394c-0.063-0.096-0.125-0.193-0.192-0.286c-0.073-0.101-0.153-0.196-0.23-0.293
		c-0.089-0.111-0.177-0.224-0.271-0.33c-0.063-0.07-0.13-0.135-0.195-0.203c-0.116-0.122-0.232-0.243-0.355-0.356
		c-0.056-0.051-0.115-0.098-0.173-0.147c-0.137-0.119-0.274-0.237-0.418-0.347c-0.061-0.046-0.125-0.087-0.188-0.131
		c-0.145-0.103-0.29-0.206-0.441-0.299c-0.078-0.048-0.16-0.09-0.239-0.135c-0.14-0.08-0.28-0.161-0.424-0.232
		c-0.101-0.05-0.206-0.092-0.309-0.138c-0.129-0.057-0.256-0.116-0.387-0.166c-0.118-0.045-0.239-0.081-0.359-0.12
		c-0.124-0.041-0.246-0.083-0.372-0.118c-0.12-0.033-0.242-0.057-0.364-0.084c-0.132-0.03-0.264-0.062-0.398-0.085
		c-0.11-0.019-0.221-0.029-0.332-0.043c-0.152-0.02-0.303-0.04-0.457-0.051c-0.094-0.006-0.188-0.006-0.283-0.01
		c-0.174-0.006-0.347-0.01-0.522-0.005c-0.083,0.002-0.167,0.011-0.251,0.016c-0.183,0.012-0.366,0.026-0.55,0.05
		c-0.028,0.004-0.055,0.003-0.083,0.008c-20.062,2.866-44.351-2.012-70.066-7.175C261.715,0.282,217.162-8.642,182.326,16.2
		c-0.524-0.108-1.066-0.165-1.622-0.165c-35.729,0-60.682,29.608-60.682,72.002c0,32.772,13.789,93.92,15.748,102.441V207.789z
		 M329,297.349c-20.108,24.955-45.996,38.698-72.894,38.698c-26.911,0-52.84-13.757-73.01-38.736
		c-19.908-24.654-31.326-57.284-31.326-89.521v-9.112c0-28.498,16.16-66.981,21.654-79.238c55.276-6.495,99.31-0.006,126.773,6.703
		c24.089,5.886,39.896,12.934,45.595,15.701l14.428,34.33v31.68C360.22,240.106,348.841,272.727,329,297.349z M180.704,32.035
		c0.772,0,1.517-0.115,2.224-0.32c2.342,0.671,4.958,0.277,7.047-1.31c29.374-22.312,71.23-13.906,111.709-5.778
		c21.237,4.265,41.547,8.343,60.306,8.343c2.89,0,5.74-0.109,8.555-0.317c4.921,17.754,9.586,62.522-3.829,117.681l-7.31-17.395
		c-0.697-1.658-1.932-3.033-3.507-3.903c-2.952-1.63-73.615-39.763-188.896-24.933c-0.02,0.002-0.038,0.008-0.058,0.01
		c-0.15,0.021-0.298,0.052-0.446,0.081c-0.116,0.022-0.234,0.041-0.348,0.068c-0.094,0.022-0.186,0.054-0.279,0.08
		c-1.015,0.283-1.939,0.754-2.738,1.374c-0.517,0.399-0.991,0.861-1.405,1.39c-0.012,0.016-0.026,0.03-0.039,0.045
		c-0.084,0.109-0.159,0.227-0.239,0.342c-0.073,0.104-0.148,0.207-0.216,0.314c-0.051,0.082-0.095,0.17-0.144,0.254
		c-0.086,0.149-0.172,0.299-0.248,0.454c-0.007,0.015-0.016,0.028-0.023,0.043c-0.619,1.282-9.674,20.183-16.721,43.296
		c-3.985-21.141-8.077-46.958-8.077-63.817C136.022,49.351,158.464,32.035,180.704,32.035z"
                          />
                          <path
                            d="M480.737,414.086c-10.786-15.799-25.828-26.767-43.5-31.717c-0.172-0.048-0.346-0.09-0.521-0.127l-63.821-13.349
		l-17.179-26.049c-0.088-0.134-0.181-0.267-0.278-0.396c-2.603-3.471-6.565-5.761-10.873-6.284c-4.305-0.522-8.702,0.753-12.06,3.5
		c-0.052,0.042-0.103,0.085-0.153,0.128l-76.325,65.701l-76.325-65.701c-0.051-0.043-0.102-0.086-0.153-0.128
		c-3.357-2.747-7.754-4.023-12.059-3.5c-4.307,0.522-8.27,2.813-10.874,6.284c-0.097,0.129-0.189,0.261-0.278,0.396l-17.179,26.049
		l-63.821,13.349c-0.175,0.037-0.349,0.079-0.521,0.127c-17.672,4.951-32.714,15.918-43.5,31.717
		c-9.866,14.45-15.299,31.891-15.299,49.108v19.843c0,15.999,14.355,29.016,32.001,29.016h183.985c0.01,0,0.02,0.001,0.03,0.001
		c0.011,0,0.022-0.001,0.033-0.001h47.918c0.011,0,0.022,0.001,0.033,0.001c0.01,0,0.02-0.001,0.03-0.001h183.985
		c17.646,0,32.001-13.017,32.001-29.016v-19.843C496.036,445.977,490.603,428.536,480.737,414.086z M342.628,352.059l15.674,23.767
		l-55.236,75.949l-17.378-17.377c-0.002-0.002-0.004-0.004-0.006-0.006l-17.902-17.902L342.628,352.059z M248.027,472.052h16.001
		c0.481,0,0.958-0.019,1.432-0.046l4.81,24.048h-28.485l4.81-24.048C247.069,472.033,247.546,472.052,248.027,472.052z
		 M272.028,448.051c0,4.411-3.589,8-8,8h-16.001c-4.412,0-8-3.589-8-8v-4.687l16.001-16.001l16.001,16.001V448.051z
		 M169.427,352.059l74.848,64.43l-17.902,17.902c-0.002,0.002-0.004,0.004-0.006,0.006l-17.378,17.377l-55.236-75.949
		L169.427,352.059z M32.018,483.037v-19.843c0-25.88,16.097-56.588,46.866-65.349l61.712-12.908l60.959,83.819
		c1.376,1.892,3.511,3.087,5.844,3.27c0.209,0.016,0.418,0.024,0.626,0.024c2.113,0,4.15-0.837,5.656-2.343l12.262-12.262
		c1.309,3.066,3.242,5.802,5.634,8.058l-6.11,30.549H48.019C39.197,496.053,32.018,490.214,32.018,483.037z M480.036,483.037
		c0,7.177-7.178,13.016-16.001,13.016H286.587l-6.11-30.549c2.392-2.255,4.325-4.992,5.634-8.058l12.262,12.262
		c1.506,1.506,3.543,2.343,5.656,2.343c0.208,0,0.417-0.008,0.626-0.024c2.333-0.183,4.468-1.378,5.844-3.27l60.959-83.819
		l61.712,12.908c30.77,8.761,46.866,39.469,46.866,65.349V483.037z"
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                  <span className="nav-link-text">Jobs</span>
                </a>
                <ul
                  id="jobs"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'jobs' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="AllJobs.html"
                        >
                          <span className="nav-link-text">All Jobs</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="jobType.html"
                        >
                          <span className="nav-link-text">Job Types</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="jobCategories.html"
                        >
                          <span className="nav-link-text">Job Categories</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-3">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('comp');
                  }}
                  aria-expanded={Tab === 'comp'}
                  aria-controls="companies"
                  data-bs-toggle="collapse"
                  data-bs-target="#companies"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <img
                        src={icA}
                        alt=""
                      />
                    </span>
                  </span>
                  <span className="nav-link-text">Organization</span>
                </a>
                <ul
                  id="companies"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'comp' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/organization"
                        >
                          <span className="nav-link-text">Companies</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/institution"
                        >
                          <span className="nav-link-text">Institution</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                        >
                          <span className="nav-link-text">
                            General Organization
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-3">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('plan');
                  }}
                  aria-expanded={Tab === 'plan'}
                  aria-controls="planMaster"
                  data-bs-toggle="collapse"
                  data-bs-target="#planMaster"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24px"
                        height="24px"
                      >
                        <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
                      </svg>
                    </span>
                  </span>
                  <span className="nav-link-text">Plan Master</span>
                </a>
                <ul
                  id="planMaster"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'plan' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="planTypes.html"
                        >
                          <span className="nav-link-text">Plan Types</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="plans.html"
                        >
                          <span className="nav-link-text">Plans</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-3">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('vip');
                  }}
                  aria-expanded={Tab === 'vip'}
                  aria-controls="vipNumber"
                  data-bs-toggle="collapse"
                  data-bs-target="#vipNumber"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <img
                        src={icB}
                        alt=""
                      />
                    </span>
                  </span>
                  <span className="nav-link-text">VIP Number</span>
                </a>
                <ul
                  id="vipNumber"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'vip' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="type.html"
                        >
                          <span className="nav-link-text">Type</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pattern.html"
                        >
                          <span className="nav-link-text">Pattern</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="vipNumber.html"
                        >
                          <span className="nav-link-text">VIP Number</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item pt-3">
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('region');
                  }}
                  aria-expanded={Tab === 'region'}
                  aria-controls="regionMaster"
                  data-bs-toggle="collapse"
                  data-bs-target="#regionMaster"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <img
                        src={icC}
                        alt=""
                      />
                    </span>
                  </span>
                  <span className="nav-link-text">Region Master</span>
                </a>
                <ul
                  id="regionMaster"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'region' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li
                        className={`nav-item ${
                          page === 'countries' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="countries"
                        >
                          <span className="nav-link-text">Countries</span>
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          page === 'state' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="state"
                        >
                          <span className="nav-link-text">State</span>
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          page === 'city' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="city"
                        >
                          <span className="nav-link-text">City</span>
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          page === 'locations' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="/locations"
                        >
                          <span className="nav-link-text">Locations</span>
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          page === 'languages' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link"
                          href="/languages"
                        >
                          <span className="nav-link-text">Languages</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li
                className="nav-item pt-3"
                onClick={() => {
                  TabToggle('comman');
                }}
              >
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    TabToggle('comman');
                  }}
                  aria-expanded={Tab === 'comman'}
                  aria-controls="commonMaster"
                  data-bs-toggle="collapse"
                  data-bs-target="#commonMaster"
                >
                  <span className="nav-icon-wrap">
                    <span className="svg-icon">
                      <img
                        src={icB}
                        alt=""
                      />
                    </span>
                  </span>
                  <span className="nav-link-text">Common Master</span>
                </a>
                <ul
                  id="commonMaster"
                  className={`nav flex-column collapse nav-children ${
                    Tab === 'comman' ? 'show' : ''
                  }`}
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="industryType.html"
                        >
                          <span className="nav-link-text">Industry Type</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="salaryType.html"
                        >
                          <span className="nav-link-text">Salary Type</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="designation.html"
                        >
                          <span className="nav-link-text">Designation</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="skillmaster.html"
                        >
                          <span className="nav-link-text">Skill Master</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="companySize.html"
                        >
                          <span className="nav-link-text">Company Size</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/firmCategory"
                        >
                          <span className="nav-link-text">Firm Category</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/qualification"
                        >
                          <span className="nav-link-text">Qualification</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/specialization"
                        >
                          <span className="nav-link-text">Specialization</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
